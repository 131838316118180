<template>
  <div>
    <v-container class="my-5">
      <UploadServerControl></UploadServerControl>
    </v-container>
  </div>
</template>

<script>
import UploadServerControl from "@/components/upload/UploadServerControl.vue";

export default {
  components: {
    UploadServerControl,
  },

  data() {
    return {};
  },

  methods: {},

  computed: {},

  mounted() {},
};
</script>

<style scoped>
/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(30px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
