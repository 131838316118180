<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-title class="pr-8">Upload Server Data</v-toolbar-title>

          <RefreshControl
            :loading="isLoading()"
            class="pl-5"
            :interval="60"
            v-on:refresh-now="refresh"
          ></RefreshControl>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="isLoading()"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="getData()"
            :items-per-page="100"
            class="elevation-1"
            dense
          >
            <template v-slot:item.last_run="{ item }">
              {{ formatDT(item.last_run) }}
            </template>

            <template v-slot:item.time_since="{ item }">
              {{ timeSince(item) }}
            </template>

            <template v-slot:item.next_run="{ item }">
              {{ formatDT(item.next_run) }}
            </template>

            <template v-slot:item.time_until="{ item }">
              {{ timeUntil(item) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn text small @click="detailsClicked(item)">
                <v-icon left color="primary">mdi-playlist-plus</v-icon>
                Details
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { toISO } from "@/lib/utils";
import RefreshControl from "@/components/utils/RefreshControl";

export default {
  components: { RefreshControl },

  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "display_name" },
        { text: "Status", value: "status" },
        { text: "Last run", value: "last_run" },
        { text: "Time since", value: "time_since" },
        { text: "Next run", value: "next_run" },
        { text: "Time until", value: "time_until" },
        { text: "Actions", sortable: false, value: "actions" },
      ],
    };
  },

  props: {},

  methods: {
    isLoading() {
      return this.$store.getters["uploadServer/isLoading"];
    },

    formatDT(value) {
      try {
        if (
          value &&
          value !== "" &&
          value !== "unknown" &&
          value !== "disabled"
        ) {
          return format(parseISO(toISO(value)), "MM-dd-yyyy hh:mm:ss aaa");
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },

    getData() {
      return this.$store.getters["uploadServer/getData"];
    },

    detailsClicked(item) {
      this.$router.push({
        name: "UploadDetails",
        params: { pipelineSlug: item.name },
      });
    },

    refresh() {
      this.$store.dispatch("notification/clear");
      this.$store.dispatch("uploadServer/getStatus");
    },

    timeSince(item) {
      if (item.last_run) {
        return formatDistanceToNow(new Date(parseISO(toISO(item.last_run))));
      } else {
        return "";
      }
    },

    timeUntil(item) {
      if (item.next_run) {
        return formatDistanceToNow(new Date(parseISO(toISO(item.next_run))));
      } else {
        return "";
      }
    },
  },

  computed: {},

  mounted() {
    this.$store.dispatch("uploadServer/getStatus");
  },

  beforeDestroy() {
    this.$store.dispatch("uploadServer/clearServerStatus");
  },
};
</script>

<style lang="css"></style>
