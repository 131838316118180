var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"pr-8"},[_vm._v("Upload Server Data")]),_c('RefreshControl',{staticClass:"pl-5",attrs:{"loading":_vm.isLoading(),"interval":60},on:{"refresh-now":_vm.refresh}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-progress-linear',{attrs:{"active":_vm.isLoading(),"indeterminate":true,"absolute":"","bottom":"","color":"blue-grey darken-3"}})],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.getData(),"items-per-page":100,"dense":""},scopedSlots:_vm._u([{key:"item.last_run",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDT(item.last_run))+" ")]}},{key:"item.time_since",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeSince(item))+" ")]}},{key:"item.next_run",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDT(item.next_run))+" ")]}},{key:"item.time_until",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeUntil(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.detailsClicked(item)}}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-playlist-plus")]),_vm._v(" Details ")],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }